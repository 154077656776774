import React from "react"
import EditorialContents from "./components/EditorialContents"

interface Props {
  contents: any
  query: any
}

const EditorialPageContainer = ({ contents, query }: Props) => {
  return <EditorialContents contents={contents.contents} query={query} />
}

export default EditorialPageContainer
