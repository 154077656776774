import React from "react"
import EditorialPageContainer from "../containers/editorialPage"
import { SeoContents } from "../features/seo/components/PageSEO"
import PageNotFoundContainer from "../containers/errors/PageNotFoundContainer"
import GenericSEO from "../features/seo/components/GenericSEO"
import { wrapPage } from "../aggregators/page"
import {
  fetchEditorialPageData,
  fetchEditorialPageDocuments,
} from "../contents/editorialPage/fetch"
import { editorialPageQuery } from "../contents/editorialPage/queries"
import { DefaultLayout } from "../layouts/Default/DefaultLayout"
import { WrapPage } from "../root"
import { getEditorialPagePath } from "../contents/editorialPage/paths"

interface Props {
  contents: any
  languageId: string
  slug: string
  category?: string
  preview: boolean
  rootData: any
}

export default function EditorialPage({
  contents: data,
  // languageId,
  // slug,
  // category,
  rootData,
}: Props) {
  // const { data } = usePreviewSubscription(editorialPageQuery, {
  //   params: { locale: languageId, slug, category },
  //   initialData: contents,
  //   enabled: isPreviewSession(),
  // })

  return (
    <WrapPage rootData={rootData}>
      <DefaultLayout>
        {data ? (
          <>
            <EditorialPageContainer contents={data} query={undefined} />
            <SeoContents page={data} />
          </>
        ) : (
          <>
            <PageNotFoundContainer />
            <GenericSEO
              title="Pagina non trovata"
              description="Pagina non trovata"
              noIndex
            />
          </>
        )}
      </DefaultLayout>
    </WrapPage>
  )
}

export async function getStaticPaths() {
  const editorialPages = await fetchEditorialPageDocuments("it_it")
  return {
    paths: editorialPages
      .filter((x: any) => !x.data?.ssr)
      .map((x: any) => ({
        params: {
          path: getEditorialPagePath(x),
        },
      })),
    // locale: "it_it",
    fallback: "blocking",
  }
}

export async function getStaticProps({ params }: any) {
  console.log("Rendering ->", JSON.stringify(params))
  return await wrapPage({
    locale: "it",
    fetchProps: async (languageId: string) => {
      const slug = params.path?.[1] ?? params.path?.[0] ?? "home"
      const category = params.path.length > 1 ? params.path[0] : ""
      const contents = await fetchEditorialPageData({
        query: editorialPageQuery,
        languageId,
        slug,
        category,
      })

      return {
        slug,
        contents,
        category,
      }
    },
    other: {
      revalidate: 10,
    },
  })
}
