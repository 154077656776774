import Link from "next/link"
import React from "react"
import CustomButton from "../../../ui/atoms/Button"
import Typography from "../../../ui/atoms/Typography"
import { CustomBox, CustomContainer } from "../../../ui/spacings"

const PageNotFoundContainer = () => {
  return (
    <CustomContainer flexCol>
      <CustomBox flex="col" justifyContent="center" alignItems="center" h100>
        <CustomBox py={20} textCenter>
          <Typography variant="h4" textClassName="mb-8">
            Pagina non trovata
          </Typography>
          <Link href="/">
            <div>
              <CustomButton>Torna in home</CustomButton>
            </div>
          </Link>
        </CustomBox>
      </CustomBox>
    </CustomContainer>
  )
}

export default PageNotFoundContainer
