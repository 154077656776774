import { SEOContext } from "@punks/core"
import React from "react"
import { Seo } from "./SEO"

interface Props {
  title: string
  description: string
  noIndex?: boolean
}

const GenericSEO = ({ title, description, noIndex }: Props) => {
  const metadata = React.useContext(SEOContext)

  return (
    <Seo
      title={`${title} | ${metadata.title}`}
      description={description}
      robots={noIndex ? "no-index" : ""}
    />
  )
}

export default GenericSEO
